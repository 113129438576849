<template>
  <div class="section">
    <header-page>
      <span
        class="size14 fw-bold-700"
        style="color: #0B5FFF;margin-right: 5px;"
      >Master</span>
      <span
        class="text-dark text-darken-5"
        style="margin-right: 5px;"
      >></span>
      <span class="text-dark fw-bold-400 size14">Bank</span>
    </header-page>

    <TableBank
      :result="result"
      :delete-data="deleteBank"
      :get-data="getBank"
      :edit-item="editItem"
      @filterBank="getFilter"
    />

    <ModalBank
      :form-data="formPayload"
      :create-item="createItem"
      :edit-id="editId"
      :edit-item="editItem"
      :update-item="updateItem"
      :clean-up-form="cleanUpForm"
      :icon-bank="iconBank"
      @getPayload="getPayload"
    />

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import {
  VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import HeaderPage from '@/components/HeaderPage.vue'
import ModalBank from '@/components/Master/Bank/Modal/ModalBank.vue'
import TableBank from '@/components/Master/Bank/TableBank.vue'

export default {
  title() {
    return 'Master Bank'
  },
  components: {
    HeaderPage,
    TableBank,
    ModalBank,
  },
  computed: {
    ...mapState({
      banks: state => state.bank.banks,
    }),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formPayload: {
        name: '',
        description: '',
        logo: '',
      },
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
    }
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getBank()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getBank()
  },
  methods: {
    ...mapActions('bank', ['getData', 'postData', 'deleteData', 'showData']),
    getPayload(value) {
      this.formPayload = value
    },
    getFilter(value) {
      this.filter = value
    },
    cleanUpForm() {
      this.editId = null
      this.iconBank = null
      this.formPayload = {
        name: '',
        description: '',
        logo: '',
      }
      this.validations = ''
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid
      this.iconBank = item.logo_url
      this.$store.commit('bank/SET_FORM_PAYLOAD', this.formPayload = {
        name: item.name,
        description: item.description,
      })
      this.$bvModal.show('modal-bank')
    },
    async getBank(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      await this.getData({
        params: queryParams,
      })
      this.result = this.banks
      this.currentPage = this.banks.current_page
      this.isLoading = false
    },
    async createItem() {
      const form = this.preparePayload()
      this.isLoading = true
      const response = await this.postData({
        id: '',
        payload: form,
      })
      if (response) {
        this.isLoading = false
        this.$bvModal.hide('modal-bank')
        this.getBank()
        successNotification(this, 'Success', 'Bank berhasil di buat')
        this.cleanUpForm()
      }
      // this.$store.dispatch('bank/postData', {
      //   query: '',
      //   payload: form,
      // })
      //   .then(() => {
      //     this.getBank()
      //     successNotification(this, 'Success', 'Bank berhasil di buat')
      //     this.cleanUpForm()
      //     this.$bvModal.hide('modal-bank')
      //     this.isLoading = false
      //   })
      //   .catch(error => {
      //     if (error.response.data.meta.validations) {
      //       this.validations = error.response.data.meta.validations
      //     }
      //     this.isLoading = false
      //   })
    },
    async updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      const response = await this.postData({
        id: this.editId,
        payload: form,
      })
      if (response) {
        this.getBank()
        successNotification(this, 'Success', 'Bank berhasil di ubah!')
        this.cleanUpForm()
        this.isLoading = false
        this.$bvModal.hide('modal-bank')
      }
      // this.$store.dispatch('bank/postData', {
      //   query: `/${this.editId}`,
      //   payload: form,
      // })
      //   .then(() => {
      //     this.$bvModal.hide('form-bank-modal')
      //     this.getBank()
      //     successNotification(this, 'Success', 'Bank berhasil di ubah!')
      //     this.cleanUpForm()
      //     this.isLoading = false
      //     this.$bvModal.hide('modal-bank')
      //   })
      //   .catch(error => {
      //     if (error.response.data.meta.validations) {
      //       this.validations = error.response.data.meta.validations
      //       errorNotification(this, 'Oops!', error.response.data.meta.messages)
      //     }
      //     this.isLoading = false
      //   })
    },
    async deleteBank(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this bank?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.deleteData({
            id: uuid,
          })
          this.getBank()
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'Bank berhasil dihapus',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}

.master-toko {
  height: 100vh
}
</style>
